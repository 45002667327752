import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const schema = z.object({
  fullName: z
    .string()
    .min(2)
    .max(100)
    .regex(
      /^[a-zA-Z\u00C0-\u00FF'!"#$%&()*+,\-.\/:;<=>?@[\\\]^_`{|}~]+(?: [a-zA-Z\u00C0-\u00FF'!"#$%&()*+,\-.\/:;<=>?@[\\\]^_`{|}~]+)+\s*$/
    ),

  email: z.string().email(),
  carQuantity: z.string(),
  password: z
    .string()
    .min(8)
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&\-_]{8,}$/,
      'invalidPassword'
    ),
  phone: z.string().min(15).max(15).optional()
});

export const PersonalDataValidator = zodResolver(schema);
