import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ArrowBack } from '@mui/icons-material';
import { ContactSalesModel } from '~/app/application/features/auth/domain/models/contact-sales-model';
import {
  useAddVehiclesMutation,
  useContactSalesMutation
} from '~/app/application/features/auth/store/hooks';
import { useRegisterUserDataStore } from '~/app/application/features/auth/store/slice/register-user-data-slice';
import EmailTextField from '~/app/application/features/components/email-text-field/email-text-field';
import MessageTextField from '~/app/application/features/components/message-text-field/name-text-field';
import NameTextField from '~/app/application/features/components/name-text-field/name-text-field';
import PhoneTextField from '~/app/application/features/components/phone-text-field/phone-text-field';
import SettingPlan from '~/app/application/features/components/success-setting/success-setting';
import BaseButton from '~/app/core/presentation/components/base-button/base-button';
import Logo from '~/app/core/presentation/components/logo/logo';

import {
  BackAndLogoBox,
  ContentBox,
  LogoBox,
  Title,
  ContactSalesBox,
  HorizontalContainer,
  ContactContainer,
  IconButtonStyled
} from './contact-sales-styles';
import { ContactSalesValidator } from './validators/contact-sales.validator';

type ContactSalesFormData = {
  name?: string;
  email?: string;
  phone?: string;
  companyName?: string;
  message?: string;
};

const ContactSalesComponent = () => {
  const { t } = useTranslation('contact-sales');
  const { registerState } = useRegisterUserDataStore();
  const [contactSales, { isLoading }] = useContactSalesMutation();
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [addVehicles] = useAddVehiclesMutation();
  const navigate = useNavigate();

  const addVehiclesRequest = async () => {
    addVehicles(registerState.vehicles)
      .unwrap()
      .catch((error) => {
        console.error(error);
      });
  };

  const contactSalesRequest = (values: ContactSalesModel) => {
    contactSales(values)
      .unwrap()
      .then(() => {
        setDisplaySuccess(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onSubmit = async (data: ContactSalesFormData) => {
    const carQuantity = registerState?.vehiclesCount?.toString() ?? '0';

    if (registerState.vehiclesCount > 0) {
      await addVehiclesRequest();
    }

    return contactSalesRequest({
      name: data.name,
      email: data.email,
      personalPhone: data.phone,
      companyName: data.companyName,
      campaignName: 'novo_self_onboarding',
      carQuantityText: carQuantity,
      cnpj: registerState.companies[0]?.taxId ?? '',
      carQuantity,
      message: data.message
    });
  };

  const formData = useForm<ContactSalesFormData>({
    resolver: ContactSalesValidator,
    values: {
      name: registerState?.user?.firstName
        ? `${registerState?.user?.firstName} ${registerState?.user?.lastName}`
        : undefined,
      email: registerState?.user?.email,
      phone: registerState?.companies[0]?.phones?.[0]
        ? `${registerState?.companies[0]?.phones?.[0]?.regionCode}${registerState?.companies[0]?.phones?.[0]?.phone}`
        : undefined,
      companyName: registerState?.companies[0]?.name
    }
  });
  const {
    handleSubmit,
    formState: { isDirty, isValid }
  } = formData;
  return (
    <ContactContainer>
      <IconButtonStyled onClick={() => navigate(-1)}>
        <ArrowBack />
      </IconButtonStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!displaySuccess ? (
          <ContactSalesBox>
            <BackAndLogoBox>
              <LogoBox>
                <Logo />
              </LogoBox>
            </BackAndLogoBox>
            <ContentBox>
              <Title>{t('form.title')}</Title>
              <HorizontalContainer>{t('form.description')}</HorizontalContainer>
              <HorizontalContainer>
                <NameTextField
                  control={formData.control}
                  label={t('form.name')}
                />
                <EmailTextField
                  control={formData.control}
                  label={t('form.email')}
                />
              </HorizontalContainer>
              <HorizontalContainer>
                <PhoneTextField
                  control={formData.control}
                  label={t('form.phone')}
                />
              </HorizontalContainer>
              <HorizontalContainer>
                <MessageTextField
                  control={formData.control}
                  label={t('form.message')}
                />
              </HorizontalContainer>
              <HorizontalContainer>
                <BaseButton
                  disabled={!isDirty || !isValid}
                  isLoading={isLoading}
                  type='submit'
                >
                  {t('form.submitButton')}
                </BaseButton>
              </HorizontalContainer>
            </ContentBox>
          </ContactSalesBox>
        ) : (
          <SettingPlan
            title={t('successMessage.title')}
            description={t('successMessage.description')}
            buttonLabel={t('successMessage.buttonLabel')}
            buttonLink={t('successMessage.buttonLink')}
            status={t('successMessage.status')}
            disableLogo={true}
          />
        )}
      </form>
    </ContactContainer>
  );
};

export default ContactSalesComponent;
