export const carQuantity = [
  {
    value: '5 a 10',
    label: '5 a 10'
  },
  {
    value: '11 a 40',
    label: '11 a 40'
  },
  {
    value: '41 de 99',
    label: '41 de 99'
  },
  {
    value: '100 de 500',
    label: '100 de 500'
  },
  {
    value: 'Acima de 500',
    label: 'Acima de 500'
  }
];
