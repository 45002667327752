import { create } from 'zustand';
import { combine } from 'zustand/middleware';

import { AddVehiclesModel } from '../../domain/models/add-vehicles-model';
import { CompanyResponseModel } from '../../domain/models/companies/company-response-model';
import { PlanResponseModel } from '../../domain/models/plans/plan-response-model';

type RegisterState = {
  user?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    active?: boolean;
    phone?: string;
  };
  isEnterpriseContact: boolean;
  companies: CompanyResponseModel[];
  vehiclesCount: number;
  vehicles: AddVehiclesModel;
  plans: PlanResponseModel[];
  carQuantity: string;
  isAuthenticated: boolean;
};

type RegisterStore = {
  registerState: RegisterState;
  setRegisterState: (state: Partial<RegisterState>) => void;
  clearStorage: () => void;
};

const loadState = (): RegisterState | undefined => {
  try {
    const serializedState = localStorage.getItem('registerState');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error(err);
  }
};

const saveState = (state: RegisterState): void => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('registerState', serializedState);
  } catch (err) {
    console.error(err);
  }
};

export const useRegisterUserDataStore = create<RegisterStore>(
  combine(
    {
      registerState: loadState() || {
        user: {},
        isEnterpriseContact: false,
        companies: [],
        vehiclesCount: 5,
        vehicles: null,
        plans: [],
        carQuantity: null,
        isAuthenticated: false
      }
    },
    (set) => ({
      setRegisterState: (state: Partial<RegisterState>) =>
        set((prev) => {
          const newState = {
            registerState: { ...prev.registerState, ...state }
          };

          saveState(newState.registerState);
          return newState;
        }),
      clearStorage: () => {
        try {
          localStorage.removeItem('registerState');
        } catch (err) {
          console.error(err);
        }
      }
    })
  )
);
